import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <div className="flex items-center justify-center p-8">
    <Link to="/">
      <StaticImage
        src="../images/header/state-storage-logo.png"
        alt="Storage Assurance"
        placeholder="tracedSVG"
      />
    </Link>
  </div>
)

export default Header